<template>
  <div class="block">
    <el-carousel
      trigger="click"
      :height="bannerHeight + 'px'"
      :autoplay="false"
      arrow="never"
    >
      <el-carousel-item v-for="(item, index) in banners" :key="index">
        <div class="custombox" v-for="(item, index) in data" :key="index">
          <div class="custombox-zon">
            <div class="custombox-one">{{ item.customboxone }}</div>
            <div class="custombox-two">{{ item.customboxtwo }}</div>
          </div>
          <div>
            <button class="applice" @click="Information">
              {{ item.customboxthree }}
            </button>
          </div>
        </div>
        <!-- <div  class="mask" > -->
          <img :src="item.imgUrl" alt="" />
          
        <!-- </div> -->

        <!-- <div style="height:100px;background-color: aqua;width: 100%; position:absolute;top:10PX" >hahaq</div> -->
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  name: "home-page",
  props: {
    banners: Array,
    data: Array,
  
  },
  data() {
    return {
      bannerHeight: 0,
      screenWidth: 0,
    
    };
  },
  methods: {
    Information() {
      this.$router.push("/fillinformation");
    },
  },
  created() {},
  beforeMounte() {},
  mounted() {
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    this.bannerHeight = (360 / 1290) * this.screenWidth;
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (400 / 1290) * this.screenWidth;
    };
  },

  beforeUpdate() {},
  updated() {},
  beforeDistroy() {},
  distroyed() {},
};
</script>
<style scoped>
.block {
  /* margin-top: 0.625rem; */
  position: relative;
  /* background: #000000; */
  
}

/* .mask {
  opacity: 50%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: 1;
} */

img {
  width: 100%;
  height: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
 
}

.custombox {
  position: absolute;
  width: 50rem;
  height: 14rem;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  z-index: 2;
}
.custombox-zon {
  width: 30rem;
  height: 8rem;
  /* border: 1px solid #000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.custombox-one {
  color: white;
  font-size: 1.5rem;
}
.custombox-two {
  color: white;
  font-size: 2rem;
  font-weight: 800;
}
.applice {
  width: 8rem;
  height: 2.3rem;
  border: 1px solid white;
  color: white;
  border-radius: 2rem;
  background: none;
  cursor: pointer;
}
.applice:hover {
  opacity: 66;
  border: none;
  background-color: rgb(135, 194, 239);
}
</style>
