<template>
  <div class="aboutbox">
    <swiper :banners="banners"></swiper>
    <!-- 公司简介 -->
    <div class="company-box">
      <div class="centerbox">
        <div class="com-content">
          <div class="com-topbox">
            <div class="com-lif">
              <div class="comfifcon">
                <div class="com-title family-title">公司简介</div>
                <div class="com-heng"></div>
              </div>
            </div>
            <div class="com-rig family-about">
              <div>
                <span style="font-size: 1.4rem" class="family-title"
                  >内蒙古自牧天成科技发展有限公司</span
                > 成立于2020年，是北京和牧兴邦网络科技有限公司合资公司。一家汇集牧场流程管理、物联网设备集成、牧场业财一体化管理、牧场大数据应用服务于一体的科技型牧业服务公司。公司秉承为畜牧业养殖管理简单化、业务流程标准化、任务管理工单化、事件发生推送化为根基，专注畜牧业信息化、现代化、智能化建设为核心。通过互联网、物联网等技术持续为畜牧业的快速发展提供有效的数据支撑。自牧天成以共享、共建、合作共赢的发展心态，自主研发拥有知识产权的（UniSheep）牧场管理平台，帮助企业打造新型数字化牧场。智慧畜牧业的发展未来是企业发展的根本动力，自牧天成本着让牧场管理更简单，养殖更轻松的企业使命；用心打造核心产品，融合新型技术，不断优化创新产品机能，为畜牧行业打造数智化服务平台而奋斗前行。
              </div>
              <div style="margin-top: 1rem">
                <span style="font-size: 1.4rem" class="family-title">
                  北京和牧兴邦网络技术有限公司</span
                >
                成立于2015年，从事信息化UniDairy平台，从单纯的阿拉钉牧场管理系统，逐步完成奶牛＋互联网的行业+牧场硬件设备的“升级”，帮助牧场建立数据化，智能化，标准化的科学的管理体系。和牧兴邦（UniDairy）平台脱胎于国内著名的牧业管理咨询公司—北京楷行管理咨询有限公司的内部管理平台。浓缩了楷行咨询多年来的牧业服务经验和管理解决方案。本着共享，共建的开发心态，面向市场推广，从大数据中找到最优的方案，制定可落实的管理流程，跟踪完工清单，直至任务结束；是一套可实施、可落地、可监督的全面牧场管理平台。
                目前和牧兴邦（UniDairy）平台完成了200多家牧场的数据化改造项目，超过50万头牛的覆盖规模。其中包括：大型集团牧业圣牧高科、原生态牧业；中型牧场金宇浩兴、义明牧场等；小型牧场万胜牧场、创辉牧场等；乳品公司绿健乳业、广州燕塘等；从繁殖、单产水平方面都极大的提高了客户牧场的效益。和牧兴邦（UniDairy）平台，专注于牧场数据化建设，不但提供软件，更是提供大量的、专业化的服务，为牧场在数据化平台导入的同时，提升管理和经济效益。
              </div>
            </div>
          </div>
          <div class="com-bottombox" v-if="specialitydisplay">
            <div class="iconbox">
              <div class="iconbox-img">
                <img src="https://qncdn.uni-sheep.com/s/Group%2055%281%29.png" alt="" />
              </div>
              <div class="icon-title family-about">专业的技术团队</div>
            </div>
            <div class="iconbox">
              <div class="iconbox-img">
                <img
                  src="https://qncdn.uni-sheep.com/s/Group%2057%20%281%29%281%29.png"
                  alt=""
                />
              </div>
              <div class="icon-title family-about">专业的技术服务</div>
            </div>
            <div class="iconbox">
              <div class="iconbox-img">
                <img
                  src="https://qncdn.uni-sheep.com/s/Group%2058%20%281%29%281%29.png"
                  alt=""
                />
              </div>
              <div class="icon-title family-about">强大的核心算法</div>
            </div>
            <div class="iconbox">
              <div class="iconbox-img">
                <img src="https://qncdn.uni-sheep.com/s/Group%2067%281%29.png" alt="" />
              </div>
              <div class="icon-title family-about">丰富的牧场经验</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 企业文化 -->
    <div class="corporate-box">
      <div class="corporate-bao" v-if="enterprisedisplay">
        <div class="corporate-topbox">
          <div class="corporate-title family-title">企业文化</div>
          <div class="corporate-hen"></div>
        </div>
        <div class="corporate-btbox">
          <div class="corporate-image">
            <div class="corporate-centerbox">
              <div class="cor-image">
                <img
                  src="https://qncdn.uni-sheep.com/s/Mask%20group%20%2811%29%281%29.png"
                  alt=""
                />
              </div>
              <div class="cor-tit family-title">使命Mission</div>
              <div class="cor-mi family-picturetext">
                让牧场管理更简单，养殖更轻松
              </div>
            </div>
          </div>
          <div class="corporate-image">
            <div class="corporate-centerbox">
              <div class="cor-image">
                <img
                  src="https://qncdn.uni-sheep.com/s/Mask%20group%20%2812%29%281%29.png"
                  alt=""
                />
              </div>
              <div class="cor-tit family-title">愿景Vision</div>
              <div class="cor-mi family-picturetext">
                为畜牧行业打造数智化服务平台
              </div>
            </div>
          </div>
          <div class="corporate-image">
            <div class="corporate-centerbox">
              <div class="cor-image">
                <img
                  src="https://qncdn.uni-sheep.com/s/Mask%20group%20%2813%29%281%29.png"
                  alt=""
                />
              </div>
              <div class="cor-tit family-title">价值观Values</div>
              <div class="cor-mi family-picturetext">卑以自牧，含章可贞</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 企业发展进程 -->
    <div class="develop-box">
      <div class="devcenter-box" v-if="developdisplay">
        <div class="devcenter-titbox">
          <div class="dev-title family-title">企业发展历程</div>
          <div class="dev-hen"></div>
        </div>
        <div class="dev-li">
          <div class="years-box">
            <div class="years-leftimg">
              <button @click="prevTab" :disabled="currentIndex === 0">
                <img
                  src="https://qncdn.uni-sheep.com/s/Union%20%283%29%281%29.png"
                  alt=""
                />
              </button>
            </div>
            <div class="years-cont">
              <div
                class="minian family-about"
                v-for="tab in visibleTabs"
                :key="tab.id"
                :class="['tab', { active: currentIndex === tab.id - 1 }]"
                @click="selectTab(tab.id - 1)"
              >
                {{ tab.title }}
              </div>
            </div>
            <div class="years-leftimg">
              <button
                @click="nextTab"
                :disabled="currentIndex === tabs.length - 1"
              >
                <img
                  src="https://qncdn.uni-sheep.com/s/Union%20%284%29%281%29.png"
                  alt=""
                />
              </button>
            </div>
          </div>

          <div class="meicontent" :key="currentTab.id">
            <div class="meicont-top">
              <div class="mei-toplif">
                <div class="mei-toptit family-about">公司进化过程</div>
                <div class="mei-topimg">
                  <img
                    src="https://qncdn.uni-sheep.com/s/Group%20829%20%281%29%281%29.png"
                    alt=""
                  />
                </div>
              </div>

              <div class="mei-topright family-about">
                <div>
                  {{ currentTab.content }}
                </div>
                <div>
                  {{ currentTab.conts }}
                </div>
              </div>
            </div>
            <div class="meicont-top">
              <div class="mei-toplif">
                <div class="mei-toptit family-about">商业落地发展</div>
                <div class="mei-topimg">
                  <img
                    src="https://qncdn.uni-sheep.com/s/Group%20829%20%281%29%281%29.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="mei-topright family-about">
                <div>
                  {{ currentTab.cont }}
                </div>
                <div>
                  {{ currentTab.describe }}
                </div>
                <div>
                  {{ currentTab.describeone }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 请与我们联系 -->
    <!-- <div class="contact-box">
      <div class="contact-centbox" v-if="contactdisplay">
        <div class="contact-top">
          <div class="contact-title family-title">请与我们联系</div>
          <div class="contact-hen"></div>
        
        </div>
        <div class="contact-bottom">
          <div class="contact-lift"></div>
          <div class="contact-right">
            <div class="contact-fir">
              <div class="fir-lift">
                <div class="fir-username">姓名 :</div>
                <div class="fir-ibox">
                  <div class="firinput">
                    <input
                      type="text"
                      v-model="form.name"
                      @blur="validateName"
                    />
                  </div>
                  <div v-if="nameError" class="fir-pla">
                    {{ nameError }}
                  </div>
                </div>
              </div>
              <div class="fir-right">
                <div class="fir-phone">手机号 :</div>
                <div class="fir-pbox">
                  <div class="firpinput">
                    <input
                      type="text"
                      v-model="form.phone"
                      @blur="validatePhone"
                    />
                  </div>
                  <div v-if="phoneError" class="fir-phla">
                    {{ phoneError }}
                  </div>
                </div>
              </div>
            </div>
            <div class="contact-sen">
              <div class="consen-lift">
                <div class="sen-institution">机构 :</div>
                <div class="sen-box">
                  <div class="sen-input" ref="s1">
                    <input
                      type="text"
                      v-model="form.selectedValue"
                      placeholder="请选择"
                      @click="showDropdown = !showDropdown"
                    />
                    <div class="sen-inimg">
                      <img
                        src="https://qncdn.uni-sheep.com/s/Vector%20%286%29%281%29.png"
                        alt="图像"
                        @click="showDropdown = !showDropdown"
                      />
                    </div>

                    <ul v-if="showDropdown" class="dropdown-menu">
                      <li
                        v-for="(item, index) in options"
                        :key="index"
                        @click="selectOption(item)"
                      >
                        {{ item }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="contact-thr">
              <div class="con-describe">描述 :</div>
              <div class="con-text">
                <textarea
                  class="con-textarea"
                  placeholder="请输入需求描述"
                  v-model="form.describe"
                ></textarea>
              </div>
            </div>
            <div class="contact-for">
              <el-button type="primary" plain size="medium" @click="submitForm"
                >发送</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 联系我们 -->
    <div class="contact-usbox">
      <div class="contact-centerbox" v-if="usdisplay">
        <div class="cont-lif">
          <div class="contcenbox">
            <div class="con-topbox">
              <div class="con-title family-title">联系我们</div>
              <div class="con-hen"></div>
            </div>
            <div class="con-bottom">
              <div class="con-ft">
                <div class="con-ion">
                  <div class="con-img">
                    <img
                      src="https://qncdn.uni-sheep.com/s/Group%20981%281%29.png"
                      alt=""
                    />
                  </div>
                  <div class="com-adres">
                    <div class="com-ato family-about">地址</div>
                    <div class="com-name family-about">
                      内蒙古自牧天成科技发展有限公司
                    </div>
                  </div>
                </div>
              </div>
              <div class="con-st">
                <div class="con-sion">
                  <div class="conphico">
                    <img
                      src="https://qncdn.uni-sheep.com/s/Group%20429%281%29.png"
                      alt=""
                    />
                  </div>
                  <div class="com-lixi">
                    <div class="con-liphon family-about">联系人</div>
                    <div class="con-liphhao family-about">
                      联系方式 ：180 4781 3330
                    </div>
                    <div class="con-liphhao family-about">
                      联系方式 ：180 1921 1729
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cont-rig">
          <baidu-map
            class="bm-view"
            :center="center"
            :zoom="zoom"
            @ready="handler"
          >
            <bm-map-type
              :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']"
              anchor="BMAP_ANCHOR_TOP_LEFT"
            ></bm-map-type>

            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
            <bm-marker
              :position="center"
              :dragging="true"
              :animation="animation"
            />
          </baidu-map>
        </div>
      </div>
    </div>
    <!-- 脚步部分 -->
    <div class="footerbox">
      <div class="centebox">
        <!-- <div class="center-first">
          <div>关于我们</div>
          <div>公司简介</div>
          <div>发展历程</div>
        </div>
        <div class="center-first">
          <div>快捷入口</div>
          <div>奶牛方案</div>
          <div>肉牛方案</div>
          <div>奶羊方案</div>
          <div>肉羊方案</div>
        </div> -->
        <div class="center-three">
          <div class="family-title">联系我们</div>
          <div class="family-about">
            公司地址：内蒙古呼和浩特赛罕区乐邦联合C6621
          </div>
          <div class="family-about">
            公司地址：江苏省苏州市姑苏区蓝文化创意产业园胥江路235号6-610
          </div>
          <div class="family-about">
            公司地址：北京市海淀区高梁桥斜街40号13号楼1层130室
          </div>
          <div class="family-about">联系电话：+0471-5613511</div>
          <div class="family-about">合作邮箱：nmgzmtc2023@163.com</div>
        </div>
        <div class="center-four">
          <div class="center-ma">
            <div class="center-maimg">
              <img src="https://qncdn.uni-sheep.com/s/Group%201006.png" alt="" />
            </div>
          </div>
          <div class="center-ma">
            <div class="center-maimg">
              <img src="https://qncdn.uni-sheep.com/s/Group%201007.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "../../../components/swiper/index.vue";
import getCurrentCityName from "../../../utils/getUserLocation";

export default {
  metaInfo: {
    title: '关于我们-自牧天成科技发展有限公司',
    // meta: [
    //   {
    //     name: 'description',
    //     content: 'This is my page description'
    //   }
    // ]
  },
  components: {
    Swiper,
  },
  props: [],
  data() {
    return {
      // banner部分
      banners: [
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%2078%281%29.png",
        },
      ],

      form: {
        // form表单绑定属性
        name: "",
        phone: "",
        selectedValue: "",
        describe: "",
      },
      showDropdown: false,
      options: [
        "牧场机构",
        "运营商机构",
        "政府机构",
        "金融机构",
        "保险机构",
        "其他机构",
      ],
      // 验证姓名字段
      nameError: "",
      //验证手机号字段
      phoneError: "",
      //中心坐标
      center: { lng: 0, lat: 0 },
      //缩放级别
      zoom: 0,
      // 滚动高度
      scrollTop: "",
      // 专业的技术团队动画
      specialitydisplay: false,
      //企业文化动画
      enterprisedisplay: false,
      //企业发展历程动画
      developdisplay: false,
      //请与我们联系
      contactdisplay: false,
      //联系我们动画
      usdisplay: false,

      animation: null,
      tabs: [
        {
          id: 1,
          title: "2023",
          content:
            "UniDairy—和牧兴邦品牌成为行业大型牧场集团数智化转型、牧业业财一体化建设领域的首选品牌",
          cont: "UniSheep精准饲喂系统1.0正式上线",
          describe: "全国服务羊只数突破15万只",
        },
        {
          id: 2,
          title: "2022",
          content: "光明、认养一头牛上线",
          cont: "UniSheep牧场管理平台1.0正式发布",
          describe: "UniSheep预算管理系统1.0正式发布",
          describeone: "服务牛头数突破150万头",
        },
        {
          id: 3,
          title: "2021",
          content: "肉羊产业咨询服务正式开启（以营养为核心）",
          describe: "UniDairy牧场管理平台在赛科星、辉山乳业 正式上线",
        },
        {
          id: 4,
          title: "2020",
          content:
            "内蒙古自牧天成科技发展有限公司成立成为国内首家专注于羊产业咨询服务、数智化牧场解决方案供应商。",
          cont: "UniDairy牧场管理平台在优然牧业、圣牧高科、原生态牧业的正式上线",
          describe: "服务牛头数突破120万头",
        },
        {
          id: 5,
          title: "2019",
          content: "数智牧业战略方向确定",
          cont: "全面预算管理平台，业财一体化解决方案集团牧业BI分析平台正式发布",
        },
        {
          id: 6,
          title: "2018",
          content:
            "和牧兴邦（UniDairy）牧业服务平台2.0正式上线引领牧业进入大数据时代",
          cont: "服务牛头数突破50万头",
        },
        {
          id: 7,
          title: "2017",
          content: "建立乳业服务，区域服务等特色服务方式",
          cont: "服务牛头数突破40万头",
        },
        {
          id: 8,
          title: "2016",
          content: "覆盖国内主要养殖区域",
          cont: "服务牛头数突破20万头",
        },
        {
          id: 9,
          title: "2015",
          content: "确定以繁殖、营养为核心，牧场体系化建设为基础的牧场服务体系",
          cont: "北京和牧兴邦网络科技有限公司成立",
        },
      ],
      currentIndex: 0,

      visibleTabs: [],
    };
  },
  methods: {
    // 企业文化移入的动画效果
    expandBox(index) {
      this.activeIndex = index;
    },
    // 姓名的验证规则
    validateName() {
      if (!this.form.name) {
        this.nameError = "请输入姓名";
      } else if (this.form.name.length < 2 || this.form.name.length > 5) {
        this.nameError = "姓名长度在2到5个字符之间";
      } else {
        this.nameError = "";
      }
    },
    // 手机号的验证规则
    validatePhone() {
      const regExp = /^1[3456789]\d{9}$/;
      if (!this.form.phone) {
        this.phoneError = "请输入手机号";
      } else if (!regExp.test(this.form.phone)) {
        this.phoneError = "请输入正确的手机号";
      } else {
        this.phoneError = "";
      }
    },
    // // 判断下拉框显示
    // toggleDropdown() {
    //   this.showDropdown = !this.showDropdown;
    // },
    // 选择机构
    selectOption(option) {
      // console.log(option);
      this.form.selectedValue = option;
      this.showDropdown = false;
    },

    // 发送
    submitForm() {
      this.validateName();
      this.validatePhone();
      // console.log(this.form.selectedValue);
      // console.log(this.form.name);
      // this.form=''
    },
    // 本地区地图定位标记
    handler({ BMap, map }) {
      console.log(BMap, map);
      this.center.lng = 111.758615;
      this.center.lat = 40.831571;
      this.zoom = 15;
      this.animation = "BMAP_ANIMATION_BOUNCE";
    },
    getCurrentCity() {
      getCurrentCityName().then((city) => {
        console.log(city); //顺利的话能在控制台打印出当前城市
      });
    },
    // 监听浏览器
    listenerFunction() {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    // 获取浏览器滚动条高度
    handleScroll(event) {
      // console.log("滚动条高度为：", event.target.scrollTop);
      this.scrollTop = event.target.scrollTop;
      const topObj = {
        90: "specialitydisplay",
        200: "enterprisedisplay",
        676: "developdisplay",
        1200: "contactdisplay",
        1800: "usdisplay",
      };
      for (const top in topObj) {
        this.scrollTop >= top && (this[topObj[top]] = true);
      }
    },
    prevTab() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.updateVisibleTabs();
      }
    },
    nextTab() {
      if (this.currentIndex < this.tabs.length - 1) {
        this.currentIndex++;
        this.updateVisibleTabs();
      }
    },
    selectTab(index) {
      this.currentIndex = index;
      this.updateVisibleTabs();
    },
    updateVisibleTabs() {
      const startIndex = Math.max(0, this.currentIndex - 4);
      const endIndex = Math.min(startIndex + 4, this.tabs.length - 1);
      this.visibleTabs = this.tabs.slice(startIndex, endIndex + 1);
    },
  },

  created() {
    this.listenerFunction();
  },
  mounted() {
    // 监听下拉选择器点击其他部分让下拉模块消失
    // document.addEventListener(
    //   "click",
    //   (e) => {
    //     let s1 = this.$refs.s1;
    //     if (!s1.contains(e.target)) {
    //       this.showDropdown = false;
    //     }
    //   },
    //   true
    // );
    this.updateVisibleTabs();
  },
  computed: {
    currentTab() {
      return this.tabs[this.currentIndex];
    },
  },
  beforeDistroy() {
    document.removeEventListener("scroll", this.listenerFunction);
  },
  // 顶顶顶
};
</script>
<style scoped lang="scss">
// 公共动画
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shrink {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.5);
  }
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

.aboutbox {
  width: 100%;
  height: 100%;
  background: #f6f9ff;
  // 公司简介
  .company-box {
    width: 100%;
    height: 50rem;
    background: #f6f9ff;
    .centerbox {
      width: 72.5rem;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .com-content {
        width: 100%;
        height: 88%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .com-topbox {
          width: 100%;
          height: 88%;
          display: flex;
          .com-lif {
            width: 15%;
            height: 100%;
            .comfifcon {
              width: 90%;
              height: 10%;
              margin: auto;
              margin-top: 2rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              .com-title {
                font-size: 1.6rem;
                font-weight: 800;
              }
              .com-heng {
                width: 6rem;
                height: 0.23rem;
                background: #4668e3;
              }
            }
          }
          .com-rig {
            width: 85%;
            height: 100%;
            text-align: justify;
            line-height: 2rem;
            font-size: 1.1rem;
          }
        }
        .com-bottombox {
          width: 100%;
          height: 15%;
          display: flex;
          background: white;
          border-radius: 3px;
          animation: slide-up 1s ease-in-out;
          animation-fill-mode: forwards;
          .iconbox {
            width: 25%;
            height: 100%;
            display: flex;
            align-items: center;
            .iconbox-img {
              width: 5.35rem;
              height: 2.3rem;
              padding-left: 2rem;
            }
            .icon-title {
              font-size: 1.3rem;
            }
          }
        }
      }
    }
  }
  //企业文化
  .corporate-box {
    width: 100%;
    height: 36.5rem;
    background: #f6f9ff;
    .corporate-bao {
      width: 1160px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      animation: slide-up 1.5s ease-in-out;
      animation-fill-mode: forwards;
      .corporate-topbox {
        width: 10rem;
        height: 4rem;
        margin: 0 auto;
        margin-top: 2.6rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-content: space-around;
        .corporate-title {
          font-size: 2rem;
          font-weight: 800;
        }
        .corporate-hen {
          width: 8rem;
          height: 0.25rem;
          background: #4668e3;
        }
      }
      .corporate-btbox {
        width: 100%;
        height: 23rem;
        display: flex;
        margin-top: 2.5rem;
        justify-content: space-between;
        .corporate-image {
          width: 19.8125rem;
          height: 100%;
          border-radius: 0.3125rem;
          background: white;
          display: flex;
          justify-content: center;
          .corporate-centerbox {
            width: 90%;
            height: 100%;

            display: flex;
            flex-direction: column;

            align-items: center;
            .cor-image {
              width: 100%;
              height: 12rem;

              margin-top: 1.5rem;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .cor-tit {
              font-size: 2rem;
              margin-top: 2rem;
            }
            .cor-mi {
              font-size: 1rem;
              color: #707072;
              margin-top: 1.5rem;
            }
          }
        }
        .corporate-image:hover {
          margin-top: -0.5rem;
          box-shadow: 8px 8px 15px 0px #e9e7e7;
          cursor: pointer;
        }
      }
    }
  }
  //企业发展进程
  .develop-box {
    width: 100%;
    height: 44.375rem;
    background: url("https://qncdn.uni-sheep.com/s/Group%20833%281%29.png") center
      center;
    background-repeat: no-repeat;
    background-size: cover;
    .devcenter-box {
      width: 100%;
      height: 100%;
      padding-top: 3rem;
      animation: slide-up 1.5s ease-in-out;
      animation-fill-mode: forwards;
      .devcenter-titbox {
        width: 30rem;
        height: 4rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-content: space-around;
        .dev-title {
          font-size: 2rem;
          font-weight: 800;
        }
        .dev-hen {
          width: 11rem;
          height: 0.25rem;
          background: #4668e3;
        }
      }
      .dev-li {
        width: 72.5rem;
        height: 30.0625rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 3.2rem;
        .years-box {
          width: 100%;
          height: 6rem;
          display: flex;
          justify-content: space-around;
          align-items: center;
          .years-leftimg {
            width: 2.25rem;
            height: 2.25rem;
            button {
              width: 2.25rem;
              height: 2.25rem;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              background: white;
              border: 1px solid #e2e4e8;
              cursor: pointer;
              img {
                width: 0.933125rem;
                height: 0.8875rem;
              }
            }
            button:hover {
              box-shadow: 0 0 0.25rem rgba(0, 0, 255, 0.836);
            }
            button:disabled {
              background-color: #e2e4e8;
            }
          }
          .years-cont {
            width: 85%;
            height: 100%;
            display: flex;
            align-items: center;
            .minian {
              width: 14rem;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 1.2rem;
              cursor: pointer;
            }
            .active {
              font-size: 4rem;
              font-weight: 800;
              color: #d9dceb;
              margin-top: -20px;
              animation: shrink 2s infinite alternate;
            }
          }
        }

        .meicontent {
          width: 35rem;
          height: 20rem;
          animation: fadeInRightBig 1s 0.2s ease both;
          margin: 0 auto;

          // margin-top: 4rem;
          .meicont-top {
            width: 100%;
            height: 50%;
            display: flex;
            justify-content: space-between;
            .mei-toplif {
              width: 30%;
              height: 100%;
              display: flex;
              justify-content: space-around;
              .mei-toptit {
                font-size: 1.2rem;
                color: #333333;
              }
              .mei-topimg {
                width: 0.9rem;
                height: 3.875rem;
                margin-top: 0.5rem;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .mei-topright {
              width: 65%;
              height: 100%;
              color: #333333;
              font-size: 1.18rem;
              line-height: 2rem;
            }
          }
        }
      }
    }
  }
  //请与我们联系
  .contact-box {
    width: 100%;
    height: 38rem;
    background: #f6f9ff;
    .contact-centbox {
      width: 72.5rem;
      height: 100%;

      margin: 0 auto;
      padding-top: 3rem;
      animation: slide-up 1.5s ease-in-out;
      animation-fill-mode: forwards;
      .contact-top {
        width: 12rem;
        height: 3.5rem;

        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-content: space-between;

        .contact-title {
          font-size: 2rem;
          font-weight: 800;
        }
        .contact-zj {
          color: gray;
          font-size: 0.9rem;
        }
        .contact-hen {
          width: 11rem;
          height: 0.25rem;
          background: #4668e3;
        }
      }
      .contact-bottom {
        width: 100%;
        height: 24rem;
        margin-top: 3.8rem;
        display: flex;
        justify-content: space-between;
        .contact-lift {
          width: 45%;
          height: 100%;
          background: url("https://qncdn.uni-sheep.com/s/Group%20202%281%29.png")
            center center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .contact-right {
          width: 52%;
          height: 100%;
          display: flex;
          flex-direction: column;
          .contact-fir {
            width: 100%;
            height: 4rem;

            display: flex;
            .fir-lift {
              width: 50%;
              height: 100%;
              display: flex;
              align-items: center;
              .fir-username {
                font-size: 1.2rem;
                margin-top: -3px;
                color: gray;
              }
              .fir-ibox {
                width: 13.5rem;
                height: 3rem;
                background: white;
                margin-left: 0.5rem;
                border: 1px solid #e5e6e7;
                .firinput {
                  width: 100%;
                  height: 100%;
                  padding: 0;
                  margin: 0;
                  background-color: transparent;
                  input {
                    width: 100%;
                    height: 100%;
                    font-size: 0.99rem;
                    border: none;
                    outline: none;
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    margin: 0;
                    padding-left: 0.5rem;
                  }
                }
                .fir-pla {
                  font-size: 0.8rem;
                  color: red;
                  margin-top: 0.2rem;
                  margin-left: 0.5rem;
                }
              }
            }
            .fir-right {
              width: 50%;
              height: 100%;
              display: flex;
              align-items: center;
              .fir-phone {
                font-size: 1.2rem;
                color: gray;
                margin-top: -0.1875rem;
              }
              .fir-pbox {
                width: 13.5rem;
                height: 3rem;
                background: white;
                margin-left: 0.5rem;
                border: 1px solid #e5e6e7;
                .firpinput {
                  width: 100%;
                  height: 100%;
                  padding: 0;
                  margin: 0;
                  background-color: transparent;
                  input {
                    width: 100%;
                    height: 100%;
                    font-size: 0.99rem;
                    line-height: 0.125rem;
                    border: none;
                    outline: none;
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    margin: 0;
                    padding-left: 0.5rem;
                  }
                }
                .fir-phla {
                  font-size: 0.8rem;
                  color: red;
                  margin-top: 0.2rem;
                  margin-left: 0.5rem;
                }
              }
            }
          }
          .contact-sen {
            width: 100%;
            height: 4rem;
            margin-top: 1rem;
            .consen-lift {
              width: 50%;
              height: 100%;

              display: flex;
              align-items: center;
              .sen-institution {
                font-size: 1.2rem;
                color: gray;
                margin-top: -0.1875rem;
              }
              .sen-box {
                width: 13.5rem;
                height: 3rem;
                background: white;
                margin-left: 0.5rem;
                border: 1px solid #e5e6e7;
                .sen-input {
                  width: 100%;
                  height: 100%;
                  padding: 0;
                  margin: 0;
                  background-color: transparent;
                  position: relative;

                  input {
                    width: 100%;
                    height: 100%;
                    font-size: 0.99rem;
                    line-height: 1.875rem;
                    border: none;
                    outline: none;
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    margin: 0;
                    padding-left: 0.5rem;
                  }
                  input::placeholder {
                    color: #b1b3b9;
                  }
                  .sen-inimg {
                    position: absolute;
                    right: 1rem;
                    top: 0.9rem;
                    width: 1.0625rem;
                    height: 0.625rem;
                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                  .dropdown-menu {
                    position: absolute;
                    width: 215px !important;
                    height: 200px !important;

                    background: white !important ;
                    margin-top: 0.8rem !important;
                    border-radius: 5px !important;
                    box-shadow: 8px 9px 10px 0px #f1f2f9 !important;
                    li {
                      padding: 0.5rem 1rem;
                      font-size: 1rem;
                      color: gray;
                    }
                    li:hover {
                      color: #4668e3;
                      font-weight: 800;
                    }
                  }
                }
              }
            }
          }
          .contact-thr {
            width: 100%;
            height: 11rem;

            margin-top: 1.5rem;
            display: flex;
            justify-content: space-between;

            // background: red;
            .con-describe {
              font-size: 1.2rem;
              color: gray;
            }
            .con-text {
              width: 91%;
              height: 8.8rem;
              border: 1px solid #e5e6e7;
              .con-textarea {
                width: 100% !important;
                height: 100% !important;
                font-size: 0.99rem !important;
                padding-left: 0.5rem !important;
                padding-top: 0.5rem !important;
                resize: none !important;
                font-family: Helvetica, Arial, sans-serif !important;
              }
              .con-textarea::placeholder {
                color: #b1b3b9;
              }
              .con-textarea:focus {
                border-color: gray;
                outline: none; /* 去除默认的焦点样式 */
              }
            }
          }
          .contact-for {
            width: 100%;
            height: 4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
          }
        }
      }
    }
  }
  // 联系我们
  .contact-usbox {
    width: 100%;
    height: 35rem;

    background: #f6f9ff;

    display: flex;
    flex-direction: column;
    justify-content: center;
    .contact-centerbox {
      width: 72.5rem;
      height: 70%;

      margin: 0 auto;
      display: flex;
      animation: slide-up 1.5s ease-in-out;
      animation-fill-mode: forwards;
      .cont-lif {
        width: 50%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .contcenbox {
          width: 95%;
          height: 80%;

          display: flex;
          flex-direction: column;

          .con-topbox {
            width: 8.6rem;
            height: 2.9rem;

            margin-left: 9.375rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .con-title {
              font-size: 2rem;
              font-weight: 800;
            }
            .con-hen {
              width: 7rem;
              height: 0.25rem;
              background: #4668e3;
            }
          }
          .con-bottom {
            width: 100%;
            height: 14rem;

            .con-ft {
              width: 100%;
              height: 50%;

              display: flex;
              align-items: center;
              .con-ion {
                width: 100%;
                height: 5rem;

                display: flex;
                align-items: center;
                justify-content: space-between;
                .con-img {
                  width: 2.5rem;
                  height: 2.5rem;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                .com-adres {
                  width: 88%;
                  height: 2.4rem;

                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  .com-ato {
                    color: gray;
                    font-size: 0.9rem;
                  }
                  .com-name {
                    font-size: 0.95rem;
                    font-weight: 800;
                    font-family: Helvetica, Arial, sans-serif;
                  }
                }
              }
            }
            .con-st {
              width: 100%;
              height: 50%;

              // background:yellow;
              display: flex;
              align-items: center;
              .con-sion {
                width: 100%;
                height: 6rem;

                display: flex;
                align-items: center;
                justify-content: space-between;
                .conphico {
                  width: 2.5rem;
                  height: 2.5rem;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                .com-lixi {
                  width: 88%;
                  height: 4.5rem;

                  display: flex;
                  flex-direction: column;
                  justify-content: space-around;
                  margin-top: 1.375rem;

                  .con-liphon {
                    color: gray;
                    font-size: 0.9rem;
                  }
                  .con-liphhao {
                    font-size: 0.95rem;
                    font-weight: 800;
                    font-family: Helvetica, Arial, sans-serif;
                  }
                }
              }
            }
          }
        }
      }
      .cont-rig {
        width: 50%;
        height: 100%;

        .bm-view {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  /* 脚步 */
  .footerbox {
    width: 100%;
    height: 28rem;

    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    /* margin-top: 3rem; */
  }
  .centebox {
    width: 91.25rem;
    height: 18rem;
    display: flex;
    justify-content: space-around;
    cursor: pointer;
  }
  .center-first {
    width: 6rem;
    // height: 14rem;
    line-height: 3rem;
    font-size: 1rem;
    font-weight: 600;
    color: white;
  }
  .center-first div:nth-child(1) {
    font-size: 1.3rem;
    font-weight: 600;
  }
  .center-three {
    width:40rem;
    // width: 32rem;
    // height: 14rem;
    line-height: 3rem;
    font-size: 1rem;
    font-weight: 600;
    color: white;
  }
  .center-three div:nth-child(1) {
    font-size: 1.3rem;
    font-weight: 600;
  }
  .center-four {
    width: 20rem;
    height: 10rem;
    display: flex;
    justify-content: space-around;
  }
  .center-ma {
    width: 6.5rem;
    height: 8rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .center-maimg {
    width: 7.5rem;
    height: 9rem;
  }
  .center-maimg img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}
</style>
